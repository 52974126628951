import { axios } from '../config/axios';
import security from './security';

const Usuarios = {};

Usuarios.create = (body) => {
  return security.validation(axios.post('/users', body));
};

Usuarios.listAll = (page = '', filtros = '') => {

  let filtro = ''

  if (filtros !== '') {
    filtro = filtros.ativo !== undefined ? filtro + '&ativo=' + filtros.ativo : ''
    filtro = filtros.nome !== undefined ? filtro + '&name=' + filtros.nome : ''
    filtro = filtros.email !== undefined ? filtro + '&email=' + filtros.email : ''
  }

  if (filtro === undefined) {
    filtro = ''
  }

  return security.validation(axios.get('/users?orderby=codigo&page_size=10&page=' + page + filtro));
};

Usuarios.listAllAtivas = (page = '', filtros = '') => {

  let filtro = ''

  if (filtros !== '') {
    filtro = filtros.ativo !== undefined ? filtro + '&ativo=' + filtros.ativo : ''
  }

  if (filtro === undefined) {
    filtro = ''
  }

  return security.validation(axios.get('/users?ativo=true&orderby=codigo&page_size=10&page=' + page + filtro));
};

Usuarios.listId = (id) => {
  return security.validation(axios.get('/users/' + id));
};

Usuarios.update = (id, body) => {
  return security.validation(axios.patch('/users/' + id, body));
};

Usuarios.delete = (id) => {
  return security.validation(axios.delete('/users/' + id));
};





export default Usuarios;
