import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Spinner } from 'react-bootstrap';

function NotFound({ addInfo }) {
  function Render() {
    return (
      <>
        <Row style={{ minHeight: '70vh' }} className="align-items-center">
          <Col className="text-center">
            <strong>
              <h3>
                <Spinner animation="grow" variant="danger" /> &nbsp; Página não encontrada! &nbsp;
                <Spinner animation="grow" variant="danger" />
              </h3>
            </strong>
            {!addInfo ? (
              <>
                <br />
                <Link to={URL.index}>Caso não seja redirecionado clique aqui!</Link>
              </>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </>
    );
  }

  return (
    <Container>
      {addInfo ? (
        <>
          <div className="mt-3 mb-4">
            <Row>
              <Col>
                <h5>{addInfo.title}</h5>
              </Col>
              <Col md="auto">
                <Link className="btn btn-outline-secondary" to={addInfo.urlGoBack}>
                  Voltar
                </Link>
              </Col>
            </Row>
          </div>

          {Render()}
        </>
      ) : (
        Render()
      )}
    </Container>
  );
}

export default NotFound;
