// AsideBar.js
import React from 'react';
import { Accordion, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { URL } from '../config/url';
import './AsideBarStyle.css';

import { FaHome, FaClipboardList } from "react-icons/fa";
import { HiUsers } from "react-icons/hi2";

import logo from '../assets/lm.png'

import { useAuth } from '../context/auth';
import metadata from '../../package.json';


const AsideBar = ({ children, open, setOpen }) => {
  const navigate = useNavigate();
  const { parcelasSelecionadas, loadingParcelas, sessionCurrent, user } = useAuth()

  function closeMenu() {
    setOpen(false)
  }

  function navigateRoute(route) {
    closeMenu()
    navigate(route)
  }

  return (
    // <div className='d-flex div-geral' >
    <div className='d-flex '>
      <Nav
        className={`flex-column justify-content-between ${open ? 'asideBarOpen' : 'asideBar'}`}
        // style={{ backgroundColor: '#2d3635', color: 'white' }}
        style={{
          backgroundColor: '#2c4f55',
          color: 'white'
        }}
      >
        <div >

          <Accordion data-bs-theme="dark" className='d-flex flex-column gap-2' style={{ backgroundColor: '#2c4f55' }}>

            {/* <p onClick={() => { navigate(URL.home); closeMenu() }} className='m-0 p-0 ps-2' style={{ cursor: 'pointer' }}>
              <span className='me-2 pb-1'><FaHome /></span>
              <span className='sidebar_title '>Home</span>
            </p> */}
            <p onClick={() => { navigate(URL.index); closeMenu() }} className='m-0 p-0 ps-2' style={{ cursor: 'pointer' }}>
              <span className='me-2 pb-1'><FaClipboardList /></span>
              <span className='sidebar_title '>CNPJ</span>
            </p>
            {user.features.includes('create:user') ? <p onClick={() => { navigate(URL.usuarios_lista); closeMenu() }} className='m-0 p-0 ps-2' style={{ cursor: 'pointer' }}>
              <span className='me-2 pb-1'><HiUsers /></span>
              <span className='sidebar_title '>Usuários</span>
            </p> : ''}
            {/* 
            <Accordion.Item eventKey="5" className='m-0 p-0'>
              <Accordion.Header
                className='m-0 p-0 ps-2'
              >
                <span className='me-2 pb-1'><LuCode /></span>
                <span className='sidebar_title pb-1'>Configurações</span>
              </Accordion.Header>
              <Accordion.Body className='p-0 ps-4 d-flex flex-column gap-1' style={{ backgroundColor: '#2c4f55' }}>

                <p onClick={() => navigateRoute(URL.cnpj_lista)} className='m-0 sidebar_subtitle pt-2' style={{ cursor: 'pointer' }}>&gt; CNPJ</p>
                <p onClick={() => navigateRoute(URL.usuarios_lista)} className='m-0 sidebar_subtitle' style={{ cursor: 'pointer' }}>&gt; Usuários</p>

              </Accordion.Body>
            </Accordion.Item> */}

            {/* <p onClick={() => navigateRoute('/teste')} className='m-0 sidebar_subtitle' style={{ cursor: 'pointer' }}>&gt; teste</p> */}
          </Accordion>
        </div>

        <div className='d-flex justify-content-center flex-column align-items-center gap-2 teste' style={{ position: 'absolute', bottom: 0 }}>
          <img src={logo} alt='logo' style={{ width: '65%' }} />
          <small className='pb-2 '>Versão {metadata.version}</small>
        </div>
      </Nav >


      <div className='page-content'>
        {children}
      </div>

    </div >
  );
};

export default AsideBar;
