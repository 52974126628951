import { axios, baseURL } from '../config/axios';
import security from './security';

const services = {};

// Password
services.login_password = (body) => {
  return security.validation(axios.post('/sessions', body));
};

// Passwordless
services.login_passwordless_email_magiclink = (body) => {
  return security.validation(axios.post('/sessions/passwordless/email-magiclink', body));
};

services.login_passwordless_email_magiclink_validate = (hash) => {
  return security.validation(axios.get(`/sessions/passwordless/email-magiclink/validate/${hash}`));
};

services.login_passwordless_email_hotp = (body) => {
  return security.validation(axios.post('/sessions/passwordless/email-hotp', body));
};

services.login_passwordless_email_hotp_validate = (hash) => {
  return security.validation(axios.get(`/sessions/passwordless/email-hotp/validate/${hash}`));
};

services.login_passwordless_sms_hotp = (body) => {
  return security.validation(axios.post('/sessions/passwordless/sms-hotp', body));
};

services.login_passwordless_sms_hotp_validate = (hash) => {
  return security.validation(axios.get(`/sessions/passwordless/sms-hotp/validate/${hash}`));
};

services.login_passwordless_whatapp_hotp = (body) => {
  return security.validation(axios.post('/sessions/passwordless/whatapp-hotp', body));
};

services.login_passwordless_whatapp_hotp_validate = (hash) => {
  return security.validation(axios.get(`/sessions/passwordless/whatapp-hotp/validate/${hash}`));
};

// QRCode
services.login_qrcode_sse = () => {
  return `${baseURL}/sessions/qrcode/sse`;
};

services.login_qrcode_validate = (client_id, hash) => {
  return axios.get(`/sessions/qrcode/sse/validate/${client_id}/${hash}`);
};

// Reset Password
services.login_recuperar_senha = (body) => {
  return security.validation(axios.post('/sessions/reset-password', body));
};

services.login_recuperar_senha_confirmar = (hash, password) => {
  return security.validation(axios.patch(`/sessions/reset-password/${hash}`, { password }));
};

// MFA HOTP
services.login_mfa_email_hotp_validate = (hotp_token) => {
  return security.validation(axios.post('/sessions/hotp/email/validate', { hotp_token }));
};

// MFA TOTP
services.totp_generate = () => {
  return security.validation(axios.get('/sessions/totp/generate'));
};

services.totp_verify = (totp_token) => {
  return security.validation(axios.post('/sessions/totp/verify', { totp_token }));
};

services.totp_url = () => {
  return security.validation(axios.get('/sessions/totp/url'));
};

services.login_mfa_totp_validate = (totp_token) => {
  return security.validation(axios.post('/sessions/totp/validate', { totp_token }));
};

// Session
services.sessionCurrent = () => {
  return security.validation(axios.get('/sessions'));
};

services.deleteSessionCurrent = () => {
  return security.validation(axios.delete('/sessions'));
};

services.sessionId = (session_id) => {
  return security.validation(axios.get(`/sessions/${session_id}`));
};

services.deleteSessionId = (session_id) => {
  return security.validation(axios.delete(`/sessions/${session_id}`));
};

services.deleteAllSession = (session_id) => {
  return security.validation(axios.delete(`/sessions/all`));
};

services.sessionList = () => {
  return security.validation(axios.get('/sessions/list'));
};

// Usuarios
services.crateUser = (body) => {
  return security.validation(axios.post('/users', body));
};
services.updateUser = (body, id) => {
  return security.validation(axios.patch('/users/' + id, body));
};
services.activeUser = (id) => {
  return security.validation(axios.get('/users/activation-account/' + id));
};
services.emailConfirmation = (id) => {
  return security.validation(axios.get('/users/email-confirmation/' + id));
};

// Perfil
services.perfil = () => {
  return security.validation(axios.get('/sessions/me'));
};

services.atualizarPerfil = (body) => {
  return security.validation(axios.patch('/sessions/me', body));
};

export default services;
