import React from 'react'
import { Button } from 'react-bootstrap'
import { FiFilter } from 'react-icons/fi'
import { GoTrash } from "react-icons/go";

const ButtonLimparAndFiltrar = ({ handleClose, reset, listAll, setFilter }) => {
  return (
    <>
      <Button variant='outline-danger' onClick={() => {
        handleClose()
        reset()
        listAll(1)
        setFilter(false)
      }}
        className='d-flex align-items-center gap-1'
      ><GoTrash /> Limpar</Button>
      <Button
        variant='success'
        type='submit'
        className='d-flex align-items-center gap-1'
      ><FiFilter /> Filtrar</Button>
    </>
  )
}

export default ButtonLimparAndFiltrar