import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Card, Container } from 'react-bootstrap';
// import { FiCheck } from 'react-icons/fi';
import SessionsServices from '../../services/sessions'
import { SweetAlert } from '../../components/sweetalert';
import { useNavigate, useParams } from 'react-router-dom';
import { URL } from '../../config/url';
import Usuarios from '../../services/Usuarios';
import TabUsuario from './TabUsuario';
import { IoMdArrowRoundBack } from 'react-icons/io';
import Loading from '../../routers/loading';

function CriarUsuarioInterno() {
  const params = useParams()

  const [loadingRecuperar, setLoadingRecuperar] = useState(false)
  const [empresas, setEmpresas] = useState([])

  const { register, getValues, formState: { errors }, setValue } = useForm();

  useEffect(() => {
    if (params.id) {
      setLoadingRecuperar(true)
      Usuarios.listId(params.id)
        .then((data) => {
          setEmpresas(data.data.empresas_usuarios)
          setValue('email', data.data.email);
          setValue('nome', data.data.name);
          setValue('username', data.data.username);
          setValue('password', '');
        })
        .catch((error) => {
          SweetAlert.error(error.response.data.message, 'Conta');
        })
        .finally(() => { setLoadingRecuperar(false) })

    } else {
      setValue('saldo', 'R$ 0,00')
    }
  }, []);

  const navigate = useNavigate()

  async function criarUsuario() {
    const user = {
      "email": getValues('email'),
      "name": getValues('nome'),
      "username": getValues('username'),
      "password": getValues('password'),
    }

    if (params.id) {
      try {
        setLoadingRecuperar(true)
        await SessionsServices.updateUser(user, params.id)
        SweetAlert.success('Usuário atualizado com sucesso!', 'Usuário')
        navigate(URL.usuarios_lista)
        setLoadingRecuperar(false)
      } catch (error) {
        setLoadingRecuperar(false)
        SweetAlert.error(error.response.data.message, 'Usuário');
      }

    } else {
      try {
        setLoadingRecuperar(true)
        await SessionsServices.crateUser(user)
        SweetAlert.success('Usuário criado com sucesso! Verifique seu email e ative sua conta', 'Usuário')
        navigate(URL.usuarios_lista)
        setLoadingRecuperar(false)
      } catch (error) {
        setLoadingRecuperar(false)
        SweetAlert.error(error.response.data.message, 'Usuário');
      }
    }
  }

  return (
    <>
      {loadingRecuperar === true ? <Loading /> : ''}
      <Container className=" pt-2">

        <div className='d-flex justify-content-between align-items-center mb-4'>
          <h2>Criar usuário</h2>
          <Button variant='outline-secondary' onClick={() => navigate(-1)} className='d-flex align-items-center gap-1'><IoMdArrowRoundBack /> Voltar</Button>
        </div>

        <Card className='col-11 col-md-12 cardShadow d-flex p-3'>
          <Container fluid>

            <TabUsuario
              params={params}
              navigate={navigate}
              register={register}
              errors={errors}
              criarUsuario={criarUsuario}
              loadingRecuperar={loadingRecuperar}
            />

          </Container>

        </Card>






      </Container >
    </>
  )
}

export default React.memo(CriarUsuarioInterno)