import Swal from 'sweetalert2'

const Basic = (icon, title, message, timeout = 5000) => {
  Swal.fire({
    toast: true,
    position: "top-end",

    icon: icon,
    title: title,
    html: message,
    timer: timeout,
    showConfirmButton: false,
    timerProgressBar: true,
    showCloseButton: true,

    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  })
}

const Question = (message, sub_message, confirmButtonText = 'Salvar', handlerConfirmation, confirmationButtonColor = '#198754') => {
  return Swal.fire({
    icon: "question",
    title: message,
    html: sub_message,

    showConfirmButton: true,
    confirmButtonText: confirmButtonText,
    confirmButtonColor: confirmationButtonColor,

    showCancelButton: true,
    cancelButtonText: 'Cancelar'
  }).then((result) => {
    if (result.isConfirmed) {
      handlerConfirmation()
      Swal.fire({
        title: "Deletado!",
        text: "Registro removido com sucesso!",
        icon: "success"
      });
    }
  });
}

export const SweetAlert = {};

SweetAlert.warn = (message, title, timeout = 5000) => {
  Basic('warning', title, message, timeout)
};

SweetAlert.error = (message, title, timeout = 5000) => {
  Basic('error', title, message, timeout)
};

SweetAlert.info = (message, title, timeout = 5000) => {
  Basic('info', title, message, timeout)
};

SweetAlert.success = (message, title, timeout = 5000) => {
  Basic('success', title, message, timeout)
};

SweetAlert.question = (message, sub_message, confirmButtonText = 'Salvar', handlerConfirmation = null, confirmationButtonColor) => {
  return Question(message, sub_message, confirmButtonText, handlerConfirmation, confirmationButtonColor)
}