import { axios } from '../config/axios';
import security from './security';

const CNPJ = {};

CNPJ.listAll = (page = '', filtros = '') => {

  let filtro = ''

  if (filtros !== '') {

    filtros.cnpj = filtros.cnpj.replace(/\D/g, '')

    let capitalInicial = ''
    let capitalFinal = ''

    console.log(filtros)

    if (filtros.capital_social !== '') {

      let newFiltro = filtros.capital_social.split('-')
      capitalInicial = Number(newFiltro[0] + '000')
      capitalFinal = Number(newFiltro[1] + '000')

    }

    // filtro = filtros.CNAE !== undefined ? filtro + '&cnae_fiscal=' + filtros.CNAE : ''
    // filtro = filtros.localizacao !== undefined ? filtro + '&ativo=' + filtros.localizacao : ''
    filtro = filtros.data_inicial !== undefined ? filtro + '&data_inicio_atividades_inicial=' + filtros.data_inicial : ''
    filtro = filtros.data_final !== undefined ? filtro + '&data_inicio_atividades_final=' + filtros.data_final : ''
    // filtro = filtros.natureza !== undefined ? filtro + '&ativo=' + filtros.natureza : ''
    filtro = filtros.situacao !== undefined ? filtro + '&situacao_cadastral=' + filtros.situacao : ''
    filtro = filtros.portes !== undefined ? filtro + '&porte_empresa=' + filtros.portes : ''
    filtro = filtros.capital_social !== undefined ? filtro + '&capital_social_inicial=' + capitalInicial : ''
    filtro = filtros.capital_social !== undefined ? filtro + '&capital_social_final=' + capitalFinal : ''
    // filtro = filtros.mei !== undefined ? filtro + '&ativo=' + filtros.mei : ''
    // filtro = filtros.simples !== undefined ? filtro + '&ativo=' + filtros.simples : ''
    filtro = filtros.uf !== undefined ? filtro + '&endereco_uf=' + filtros.uf : ''
    filtro = filtros.cidade !== undefined ? filtro + '&endereco_cidade=' + filtros.cidade : ''
    filtro = filtros.cnpj !== undefined ? filtro + '&cnpj=' + filtros.cnpj : ''
    // filtro = filtros.razao_social !== undefined ? filtro + '&razao_social=' + filtros.razao_social : ''
  }

  if (filtro === undefined) {
    filtro = ''
  }

  return security.validation(axios.get('/cnpj?orderby=-data_inicio_atividades&page=' + page + filtro));
};

CNPJ.listId = (id) => {
  return security.validation(axios.get('/cnpj/' + id));
};


export default CNPJ;
