import React, { useState } from "react";

import Router from "./router";
import Header from "../components/Header";
import { useAuth } from '../context/auth';
import AsideBar from '../components/AsideBar';

function Main(props) {

  const { isConnected, user, sessionCurrent, showMenu } = useAuth()
  const [open, setOpen] = useState(false)

  return (
    <div style={{ backgroundColor: '#f8f9fb', minHeight: '100vh' }}>
      {isConnected === true ?
        <>
          <Header
            open={open}
            setOpen={setOpen}
          />
          <AsideBar
            open={open}
            setOpen={setOpen}
          >
            <div style={{ marginTop: 60 }}>
              <Router />
            </div>
          </AsideBar>
        </>
        : <Router />
      }
      {/* <Container fluid> */}
      {/* </Container> */}
    </div >
  );
}

export default Main;
