import { Routes, Route, useLocation, Navigate } from 'react-router-dom';

import { URL, SUBHOST } from '../config/url';
import { useAuth } from '../context/auth';

import NotFound from './notfound';

import Login from '../pages/login';
import Home from '../pages/home';

import Perfil from '../pages/perfil';
import RecuperarSenha from '../pages/recuperar_senha';
import RecuperarSenhaConfirmar from '../pages/recuperar_senha_confirmar';
import ListaUsuarios from '../pages/lista_usuarios';
import ListaCNPJ from '../pages/lista_cnpj';
import UsuariosCadastro from '../pages/cadastro_usuarios';
import AtualizarUsuarioConfirmar from '../pages/confirmar_alterar_email';
import Teste from '../pages/lista_teste';
import DadosCNPJ from '../pages/dados_cnpj';
import CriarUsuarioConfirmar from '../pages/criar_usuario_confirmar';

function Router() {

  function RequireAuth({ children }) {
    const location = useLocation();
    const { isConnected } = useAuth();

    if (!isConnected) return <Navigate to={URL.login} state={{ from: location }} replace />;

    return children;
  }

  function LoginAuth({ children }) {
    const location = useLocation();
    const { isConnected } = useAuth();

    if (isConnected) return <Navigate to={URL.cnpj_lista} state={{ from: location }} replace />;

    return children;
  }

  return (
    <Routes>
      {SUBHOST && <Route path={'/'} element={<Navigate to={URL.cnpj_lista} state={{ from: '/' }} replace />} />}
      <Route path={URL.login} element={<LoginAuth><Login /></LoginAuth>} />
      <Route path={URL.login_recuperar_senha} element={<RecuperarSenha />} />
      <Route path={URL.login_recuperar_senha_confirmar + '/:hash'} element={<RecuperarSenhaConfirmar />} />
      <Route path={URL.login_confirmar_usuario + '/:hash'} element={<LoginAuth><CriarUsuarioConfirmar /></LoginAuth>} />

      {/* <Route path={URL.index} element={<RequireAuth><Home /></RequireAuth>} /> */}
      {/* <Route path={URL.home} element={<RequireAuth><Home /></RequireAuth>} /> */}
      <Route path={URL.perfil} element={<RequireAuth><Perfil /></RequireAuth>} />
      <Route path={URL.perfil_confirmar_email + '/:hash'} element={<RequireAuth><AtualizarUsuarioConfirmar /></RequireAuth>} />

      <Route path={URL.usuarios_lista} element={<RequireAuth><ListaUsuarios /></RequireAuth>} />
      <Route path={URL.usuarios_cadastro} element={<RequireAuth><UsuariosCadastro /></RequireAuth>} />
      <Route path={URL.usuarios_cadastro + '/:id'} element={<RequireAuth><UsuariosCadastro /></RequireAuth>} />

      <Route path={URL.index} element={<RequireAuth><ListaCNPJ /></RequireAuth>} />
      <Route path={URL.cnpj_lista} element={<RequireAuth><ListaCNPJ /></RequireAuth>} />
      <Route path={URL.cnpj_lista + '/:id'} element={<RequireAuth><DadosCNPJ /></RequireAuth>} />

      <Route path={'/teste'} element={<RequireAuth><Teste /></RequireAuth>} />


      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Router;
