import React from 'react'
import './style.css'

const TableHeadRowCenter = ({ text, selFunction = '' }) => {

  if (text.toUpperCase() === 'ATIVO') {
    return (
      <th style={{ whiteSpace: "nowrap", paddingLeft: 10, paddingRight: 10, color: '#2d3635', maxWidth: 70 }} className='text-center '>{text}</th>
    )
  }
  if (text.toUpperCase() === 'AÇÕES') {
    return (
      <th style={{ whiteSpace: "nowrap", paddingLeft: 10, paddingRight: 10, color: '#2d3635', maxWidth: 100 }} className='text-center '>{text}</th>
    )
  }
  if (text.toUpperCase() === 'CPF/CNPJ') {
    return (
      <th style={{ whiteSpace: "nowrap", paddingLeft: 10, paddingRight: 10, color: '#2d3635', maxWidth: 100 }} className='text-center '>{text}</th>
    )
  }
  if (text.toUpperCase() === 'ENTIDADE') {
    return (
      <th style={{ whiteSpace: "nowrap", paddingLeft: 10, paddingRight: 10, color: '#2d3635', maxWidth: 80 }} className='text-center '>{text}</th>
    )
  }
  if (text.toUpperCase() === 'CÓDIGO') {
    return (
      <th style={{ whiteSpace: "nowrap", paddingLeft: 10, paddingRight: 10, color: '#2d3635', maxWidth: 70 }} className='text-center'>{text}</th>
    )
  }
  if (text.toUpperCase() === 'TOTAL') {
    return (
      <th style={{ whiteSpace: "nowrap", paddingLeft: 10, paddingRight: 10, color: '#2d3635', maxWidth: 60 }} className='text-center'>{text}</th>
    )
  }
  if (text.toUpperCase() === 'SEL') {
    return (
      <th style={{ whiteSpace: "nowrap", paddingLeft: 10, paddingRight: 10, color: '#2d3635', maxWidth: 60, cursor: 'pointer' }} className='text-center' onClick={() => selFunction()}>{text}</th>
    )
  }
  return (
    <th style={{ whiteSpace: "nowrap", paddingLeft: 10, paddingRight: 10, color: '#2d3635' }} className='text-center '>{text}</th>
  )
}

export default TableHeadRowCenter