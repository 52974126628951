import { Spinner, Container, Row, Col } from "react-bootstrap";

function Loading({ show, addInfo }) {
  if (!show) return <></>;

  function Render() {
    return (
      <>
        <Row style={{ minHeight: "70vh" }} className="align-items-center">
          <Col className="text-center">
            <strong>
              <h4>Carregando aguarde um momento...</h4>
            </strong>
            <Spinner animation="border" size="lg" />
          </Col>
        </Row>
      </>
    );
  }

  return (
    <Container>
      {addInfo ? (
        <>
          <div className="mt-3 mb-4">
            <Row>
              <Col>
                <h5>{addInfo.title}</h5>
              </Col>
            </Row>
          </div>

          {Render()}
        </>
      ) : (
        Render()
      )}
    </Container>
  );
}

export default Loading;
