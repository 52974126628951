const SUBHOST = process.env.REACT_APP_SUBHOST

const URL = {
  index: SUBHOST + '/',
  home: SUBHOST + '/home',

  login: SUBHOST + '/login',
  login_recuperar_senha: SUBHOST + '/login/recuperar-senha',
  login_recuperar_senha_confirmar: SUBHOST + '/login/recuperar-senha/confirmar',
  login_criar_usuario: SUBHOST + '/login/criar-usuario',
  login_confirmar_usuario: SUBHOST + '/registrar/confirmar',

  registrar: SUBHOST + '/registrar',
  registrar_confirmar: SUBHOST + '/registrar/confirmar',

  perfil: SUBHOST + '/perfil',
  perfil_confirmar_email: SUBHOST + 'perfil/confirmar_email',

  // produtos_lista: SUBHOST + '/produtos',
  // produtos_cadastro: SUBHOST + '/produtos/cadastro',
  // produtos_categorias_lista: SUBHOST + '/produtos/categorias',
  // produtos_categorias_cadastro: SUBHOST + '/produtos/categorias/cadastro',

  // tipo_pagamento_lista: SUBHOST + '/tipo-pagamento',
  // tipo_pagamento_cadastro: SUBHOST + '/tipo-pagamento/cadastro',

  // empresas_lista: SUBHOST + '/empresas',
  // empresas_lista_select: SUBHOST + '/empresas/selecionar',
  // empresas_cadastro: SUBHOST + '/empresas/cadastro',

  // clientes_lista: SUBHOST + '/clientes',
  // fornecedores_lista: SUBHOST + '/fornecedores',
  // transportadoras_lista: SUBHOST + '/transportadoras',
  // pessoa_cadastro: SUBHOST + '/pessoa/cadastro',

  // pedido_lista: SUBHOST + '/pedido',
  // pedido_cadastro: SUBHOST + '/pedido/cadastro',

  // contas_lista: SUBHOST + '/contas',
  // contas_cadastro: SUBHOST + '/contas/cadastro',

  // despesas_lista: SUBHOST + '/despesas',
  // despesas_cadastro: SUBHOST + '/despesas/cadastro',

  // receitas_lista: SUBHOST + '/receitas',
  // receitas_cadastro: SUBHOST + '/receitas/cadastro',

  // caixa_lista: SUBHOST + '/caixa',
  // caixa_cadastro: SUBHOST + '/caixa/cadastro',

  // receber_lista: SUBHOST + '/receber',
  // receber_cadastro: SUBHOST + '/receber/cadastro',

  // pagar_lista: SUBHOST + '/pagar',
  // pagar_cadastro: SUBHOST + '/pagar/cadastro',

  // duplicata_detalhes: SUBHOST + '/duplicata/detalhes',
  // receber_agrupar: SUBHOST + '/receber/agrupar',

  // lista_parcelas_selecionadas: SUBHOST + '/parcelas_selecionadas',

  // credito_lista: SUBHOST + '/credito',
  // credito_cadastro: SUBHOST + '/credito/cadastro',

  // movimentacao_lista: SUBHOST + '/produtos/movimentacao',
  // movimentacao_cadastro: SUBHOST + '/produtos/movimentacao/cadastro',

  // banco_lista: SUBHOST + '/banco',
  // banco_cadastro: SUBHOST + '/banco/cadastro',

  usuarios_lista: SUBHOST + '/usuarios',
  usuarios_cadastro: SUBHOST + '/usuarios/cadastro',

  cnpj_lista: SUBHOST + '/cnpj',

};

export { URL, SUBHOST };
