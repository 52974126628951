import React from 'react'
import { Table } from 'react-bootstrap';
import './style.css'

const TableList = ({ children }) => {

  return (
    <div className='mb-3 mt-3 mx-3' style={{ minHeight: 350, overflowY: 'auto' }}>
      <table style={{ minWidth: '100%' }}>
        {/* <Table striped bordered hover size='sm'> */}
        {children}
        {/* </Table> */}
      </table>
    </div>
  )
}

export default TableList