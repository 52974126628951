import React, { useState, useEffect, createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import SessionsServices from '../services/sessions';
import { URL } from '../config/url';
import { SweetAlert } from '../components/sweetalert';
import Empresa from '../services/Empresa';

const DEFAULT = {
  Login: async (email, senha, token) => { },
  LoginToken: ({ token }) => { },
  DeleteSession: async () => { },
  Me: async (redirect = true) => { },
  ApiError: async (title, error, setLoadingError = null) => { },

  user: {},
  sessionCurrent: {},
  setUser: {},
  isConnected: false,
  isLoading: true,
};

const AuthContext = createContext(DEFAULT);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [sessionCurrent, setSessionCurrent] = useState({});
  const [isConnected, setConnected] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [parcelasSelecionadas, setParcelasSelecionadas] = useState([]);
  const [loadingParcelas, setLoadingParcelas] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const [empresaSelecionada, setEmpresaSelecionada] = useState('');
  const [duplicataParcelas, setDuplicataParcelas] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    async function Start() {
      try {
        await Me();
      }
      catch (error) {

      }
      setLoading(false);
    }

    Start();

    // eslint-disable-next-line
  }, []);

  async function Me() {
    try {
      // const validated = await Validation()

      // if (validated === true) {
      const { data } = await SessionsServices.perfil()
      setUser(data);
      setConnected(true);

      // if (redirect) navigate(URL.home)
      //   return data
      // }

      return false
    } catch (error) {
      console.error('Me: ' + error?.message)
      throw error
    }
  }

  // async function Validation() {
  //   try {
  //     // let next = true

  //     const { data } = await SessionsServices.sessionCurrent()
  //     if (Object.keys(data).length === 0) return false

  //     setSessionCurrent(data)

  //     if (data.features.includes('create:user') && data.features.includes('read:user:activation_account')) return

  //     if (data.empresa) {
  //       setEmpresaSelecionada(data.empresa.razao_social)
  //       setConnected(true)
  //       // return true
  //       // setConnected(true)
  //       // navigate(URL.empresas_lista_select)
  //       // next = false
  //       // return next
  //     }
  //     if (data.empresa === undefined) {
  //       Empresa.listAllSelect()
  //         .then((data) => {
  //           if (data.data.length === 0) {
  //             navigate(URL.empresas_cadastro)
  //             setConnected(true)
  //             setShowMenu(false)
  //           } else {
  //             setConnected(true)
  //             setShowMenu(false)
  //             navigate(URL.empresas_lista_select)
  //             // next = false
  //             // return next
  //           }
  //         })
  //         .catch((error) => {
  //           SweetAlert.error('Login: ' + error.response.data.message)
  //         })

  //     }
  //     // if (data.empresa) {
  //     //   setConnected(true)
  //     //   navigate(URL.empresas_cadastro)
  //     //   next = false
  //     //   return next
  //     // }

  //     return true
  //   } catch (error) {
  //     console.error('Validation: ' + error?.message)
  //     throw error
  //   }
  // }

  async function Login(email, senha) {
    try {
      await SessionsServices.login_password({ ...(email.includes('@')) ? { email } : { username: email }, password: senha })
      setUser({
        email
      })
      return await Me(true);
    } catch (error) {
      SweetAlert.error('Login: ' + error?.message)
      throw error
    }
  }

  //logout
  async function DeleteSession() {
    try {
      await SessionsServices.deleteSessionCurrent()
      setConnected(false)
    } catch (error) {
      console.error('DeleteSession: ' + error.message)
      throw error
    } finally {
      setUser('');
      setConnected(false);

      navigate(URL.index)
    }
  }


  return (
    <AuthContext.Provider
      value={{
        Login,
        DeleteSession,
        Me,
        isConnected,
        user,
        setUser,
        isLoading,
        sessionCurrent,
        parcelasSelecionadas,
        setParcelasSelecionadas,
        loadingParcelas,
        setLoadingParcelas,
        showMenu,
        setShowMenu,
        empresaSelecionada,
        setEmpresaSelecionada,
        duplicataParcelas,
        setDuplicataParcelas
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
