import React from 'react'
import './style.css'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const TableBodyRowCenter = ({ text, alt = '', tooltip = '' }) => {
  if (alt === 'Ativo') {
    return (
      <td style={{ maxWidth: 70 }} className='tableRowCenter'>{text}</td>
    )
  }
  
  if (alt === 'Código') {
    return (
      <td style={{ maxWidth: 70 }} className='tableRowCenter'>{text}</td>
    )
  }

  const renderDefault = () => {
    return (
        <td className='tableRowCenter'>
          {text}
        </td>          
    )
  }

  const renderTooltip = () => {
    return (
    <>
      <OverlayTrigger
        placement='bottom'
        overlay={
          <Tooltip id={`tooltip-${text}`} style={{position:"fixed"}}>
            {tooltip}
          </Tooltip>
        }>
        {renderDefault()}
      </OverlayTrigger>      
    </>      
    )    
  }

  return (
    <>
        {(tooltip) ? renderTooltip() :  renderDefault() }
    </>
  )
}

export default TableBodyRowCenter