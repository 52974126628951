import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
// import { useForm } from 'react-hook-form'
import CNPJ from '../../services/CNPJ'
import { SweetAlert } from '../../components/sweetalert'
import { Card } from 'react-bootstrap'
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { CiCalendarDate } from "react-icons/ci";
import { SiTarget } from "react-icons/si";
import { FaFilter } from "react-icons/fa";
import { CiMoneyBill } from "react-icons/ci";
import { HiOutlineWallet } from "react-icons/hi2";
import { FaUsers } from "react-icons/fa";
import { CiStickyNote } from "react-icons/ci";
import { format, addHours } from 'date-fns'
import { formatarDocumento } from '../../utilities/formatCpfCnpj'
import { formatMoney } from '../../utilities/formatMoneyValue'

import './style.css'
import CardDados from './CardDados'

const DadosCNPJ = () => {

  const params = useParams()
  // const { register, getValues, setValue } = useForm();

  const [empresa, setEmpresa] = useState(null)
  const [telefones, setTelefones] = useState(0)
  const [tituloOriginal, setTituloOriginal] = useState(document.title)

  const [loading, setLoading] = useState(false)

  // document.addEventListener('DOMContentLoaded', function () {
  //   document.title = 'Novo Título';
  // });

  useEffect(() => {
    if (params.id) {
      setLoading(true)
      CNPJ.listId(params.id)
        .then((data) => {
          setEmpresa(data.data)

          let totalTelefones = 0

          if (data.data.telefone1 !== '') {
            totalTelefones += 1
          }

          if (data.data.telefone2 !== '') {
            totalTelefones += 1
          }

          if (data.data.fax !== '') {
            totalTelefones += 1
          }

          document.title = data.data.razao_social

          setTelefones(totalTelefones)
          setLoading(false)
        })
        .catch((error) => {
          SweetAlert.error(error.response.data.message, 'CNPJ');
          setLoading(false)
        })
    }
    return () => {
      document.title = tituloOriginal; // Restaura o título original
    };
  }, [params.id, tituloOriginal]);

  return (
    <>
      {loading === true ? 'carregando' : ''}

      <div className='mx-3'>
        <h1 className='mb-3' style={{ color: '#2c4f55' }}>{empresa?.razao_social}</h1>
        {
          empresa !== null ? <span style={{ color: '#2c4f55' }}>
            {empresa?.razao_social} atua no ramo de {empresa?.cnae_fiscal_descricao}, com início das atividades em {format(addHours(empresa?.data_inicio_atividades, 3), 'dd/MM/yyyy')} localizada em {empresa?.endereco_cidade} - {empresa?.endereco_uf} e possui o CNPJ {formatarDocumento(empresa?.cnpj)}
            . A empresa encontra-se {empresa?.situacao_cadastral} com a data da última atualização cadastral em {format(addHours(empresa?.situacao_cadastral_data, 3), 'dd/MM/yyyy')}.
          </span> : ''
        }
        <div className='d-flex align-items-center mb-3 flex-wrap gap-2 mt-3'>

          <CardDados
            icon={<FaPhoneAlt size={30} color={'#2c4f55'} />}
            label={'TELEFONE'}
            value={telefones}
          />
          <CardDados
            icon={<MdEmail size={30} color={'#2c4f55'} />}
            label={'E-MAILS'}
            value={empresa?.email === '' ? 0 : 1}
          />
          <CardDados
            icon={<CiCalendarDate size={30} color={'#2c4f55'} />}
            label={'DATA DE ABERTURA'}
            value={empresa?.data_inicio_atividades === undefined ? '' : format(addHours(empresa?.data_inicio_atividades, 3), 'dd/MM/yyyy')}
          />
          <CardDados
            icon={<SiTarget size={30} color={'#2c4f55'} />}
            label={'SITUAÇÃO'}
            value={empresa?.situacao_cadastral}
          />
          <CardDados
            icon={<FaFilter size={30} color={'#2c4f55'} />}
            label={'TIPO'}
            value={empresa?.matriz_filial}
          />
          <CardDados
            icon={<CiMoneyBill size={30} color={'#2c4f55'} />}
            label={'CAPITAL SOCIAL'}
            value={Number(empresa?.capital_social) === 0 ? 'R$ 0,00' : formatMoney(empresa?.capital_social)}
          />
          <CardDados
            icon={<HiOutlineWallet size={30} color={'#2c4f55'} />}
            label={'PORTE ESTIMADO'}
            value={empresa?.porte_empresa === null ? 'NÃO INFORMADO' : empresa?.porte_empresa.replace(/_/g, ' ')}
          />
          <CardDados
            icon={<FaUsers size={30} color={'#2c4f55'} />}
            label={'SÓCIOS'}
            value={empresa?.socios.length}
          />
          <CardDados
            icon={<CiStickyNote size={30} color={'#2c4f55'} />}
            label={'TRIBUTAÇÃO'}
            value='2'
          />
        </div>

        <div className='d-flex gap-3 flex-wrap'>
          <Card className='cardShadow col-12 col-md-7' >
            <Card.Body className='p-3'>

              <h3>Cartão CNPJ</h3>

              <p className='card_label'>CNPJ:</p>
              <p>{formatarDocumento(empresa?.cnpj)}</p>

              <p className='card_label'>RAZÃO SOCIAL:</p>
              <p>{empresa?.razao_social}</p>

              {
                empresa?.fantasia !== '' ?
                  <>
                    <p className='card_label'>NOME FANTASIA:</p>
                    <p>{empresa?.fantasia}</p>
                  </>
                  : ''
              }

              <p className='card_label'>CNAE PRINCIPAL:</p>
              <p>{empresa?.cnae_fiscal} - {empresa?.cnae_fiscal_descricao}</p>

              {/* <p className='card_label'>CNAES SECUNDÁRIOS:</p>
              <p>{empresa?.cnpj}</p> */}

              <p className='card_label'>NATUREZA JURÍDICA:</p>
              <p>{empresa?.natureza_juridica} - {empresa?.natureza_juridica_descricao}</p>

              <p className='card_label'>ENDEREÇO:</p>
              <p className='mb-0 pb-0'>
                {empresa?.endereco_logradouro} {empresa?.endereco_numero} - {empresa?.endereco_bairro}, {empresa?.endereco_cidade}-{empresa?.endereco_uf} CEP: {empresa?.endereco_cep.replace(/^(\d{5})(\d{3})$/, '$1-$2')}
              </p>
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(empresa?.endereco_logradouro + ', ' + empresa?.endereco_numero + ', ' + empresa?.endereco_bairro + ', ' + empresa?.endereco_cidade + '-' + empresa?.endereco_uf + ' ' + empresa?.endereco_cep.replace(/^(\d{5})(\d{3})$/, '$1-$2'))}`} target="_blank" rel="noopener noreferrer">Ver no Google Maps</a>

              {empresa?.email !== '' ?
                <>
                  <p className='card_label mt-3'>E-MAIL:</p>
                  <a href={`mailto:${empresa?.email}`}>{empresa?.email}</a>
                </>
                : ''}

              <p className='card_label mt-3'>TELEFONES:</p>
              <div className='d-flex gap-3 align-items-center'>
                <span>({empresa?.ddd1}) {empresa?.telefone1}</span>
                <a href={`tel:${empresa?.ddd1}${empresa?.telefone1}`} target='_blank' rel="noreferrer"><FaPhoneAlt color={'#2c4f55'} /></a>
                <a href={`https://wa.me/55${empresa?.ddd1}${empresa?.telefone1}`} target='_blank' rel="noreferrer"><FaWhatsapp color={'#2c4f55'} /></a>
              </div>

              {
                empresa?.ddd2 !== '' && empresa?.telefone2 !== '' ?
                  <div className='d-flex gap-3 align-items-center'>
                    <span>({empresa?.ddd2}) {empresa?.telefone2}</span>
                    <a href={`tel:${empresa?.ddd2}${empresa?.telefone2}`} target='_blank' rel="noreferrer"><FaPhoneAlt color={'#2c4f55'} /></a>
                    <a href={`https://wa.me/55${empresa?.ddd2}${empresa?.telefone2}`} target='_blank' rel="noreferrer"><FaWhatsapp color={'#2c4f55'} /></a>
                  </div>
                  : ''
              }

              {
                empresa?.ddd_fax !== '' && empresa?.fax !== '' ?
                  <div className='d-flex gap-3 align-items-center'>
                    <span>({empresa?.ddd_fax}) {empresa?.fax}</span>
                    <a href={`tel:${empresa?.ddd_fax}${empresa?.fax}`} target='_blank' rel="noreferrer"><FaPhoneAlt color={'#2c4f55'} /></a>
                    <a href={`https://wa.me/55${empresa?.ddd_fax}${empresa?.fax}`} target='_blank' rel="noreferrer"><FaWhatsapp color={'#2c4f55'} /></a>
                  </div>
                  : ''
              }

            </Card.Body>
          </Card>

          <Card className='cardShadow col-12 col-md-4 mb-3'>
            <Card.Body className='p-3'>

              <h3>Quadro de sócios administradores</h3>

              {empresa?.socios.map((socio, index) => {
                return (
                  <p className='ms-2'><b>{socio.nome}</b></p>
                )
              })}

            </Card.Body>
          </Card>
        </div>

      </div >
    </>
  )
}

export default DadosCNPJ