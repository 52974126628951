import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loading = () => {
  return (
    <div
      className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', zIndex: 10 }}
    >
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      <div className="loading-text ms-2">Carregando<span className="dot" id="dot-1">.</span><span className="dot" id="dot-2">.</span><span className="dot" id="dot-3">.</span></div>
    </div>
  );
}

export default Loading;
