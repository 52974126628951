import React from 'react'
import { Card } from 'react-bootstrap'

const CardDados = ({ icon, label, value }) => {
  return (
    <Card className='cardShadow col-12 col-md-auto'>
      <Card.Body className='p-3 d-flex align-items-center gap-3'>
        {icon}
        <div>
          <span className='card_label'>{label}</span>
          <p className='p-0 m-0'>{value}</p>
        </div>

      </Card.Body>
    </Card>

  )
}

export default CardDados