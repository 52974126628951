export const formatCPFOrCNPJ = (e, setValue, input) => {
  // Remove non-digit characters
  const value = e.target.value.replace(/\D/g, '');

  // Determina se é CPF ou CNPJ com base no comprimento da entrada
  let newData = '';
  if (value.length <= 11) {
    newData = value
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  } else {
    newData = value
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  }

  setValue(input, newData);
};
