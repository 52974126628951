import React from 'react'
import './style.css'
import { IoMdPin } from "react-icons/io";
import { FaRegCalendarAlt, FaCheckCircle } from "react-icons/fa";
import { BsFillXCircleFill } from "react-icons/bs";
import { format, addHours } from 'date-fns'
import { formatarDocumento } from '../../utilities/formatCpfCnpj'

const CardEmpresa = ({ item }) => {

  // let dataInicioAtividades = 

  return (
    <div className='px-3 divEmpresa mt-3'>
      {/* <a href="/48968777000251" rel="noreferrer" target="_blank" className='empresa-title'>{item.razao_social}</a> */}
      <a href={"cnpj/" + item.cnpj} rel="noreferrer" target="_blank" className='empresa-title'>{item.razao_social !== null ? item.razao_social : 'Empresa sem razão social'}</a>
      <div className='d-flex gap-0 gap-md-3 mt-3 flex-column flex-md-row'>
        {
          item.situacao_cadastral === 'ATIVA' ?
            <p className='ativo-text'><FaCheckCircle /> Ativa</p> :
            <p className='inativo-text'><BsFillXCircleFill /> Inativo</p>
        }

        <p className='data-text'><FaRegCalendarAlt /> {format(addHours(item.data_inicio_atividades, 3), 'dd/MM/yyyy')}</p>
        <p className='cidade-text'> <IoMdPin />{item.endereco_cidade} - {item.endereco_uf}</p>
      </div>
      <p className='empresa-description'>
        {item.razao_social} atua no ramo de {item.cnae_fiscal_descricao}, com início das atividades em {format(addHours(item.data_inicio_atividades, 3), 'dd/MM/yyyy')} localizada em {item.endereco_cidade} - {item.endereco_uf} e possui o CNPJ {formatarDocumento(item.cnpj)}.
        A empresa encontra-se {item.situacao_cadastral} {item.situacao_cadastral_data === null ? '' : `com a data da última atualização cadastral em ${format(addHours(item.situacao_cadastral_data, 3), 'dd/MM/yyyy')}`}.
      </p>

    </div>
  )
}

export default CardEmpresa