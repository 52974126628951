import React, { useEffect, useState } from 'react'
import TopTitlePage from '../../components/TopTitlePage'
import { Button, Card, Container, Form, Offcanvas, Row } from 'react-bootstrap'
import Loading from '../../components/Loading'
import { SweetAlert } from '../../components/sweetalert'

import ReactPaginate from 'react-paginate';
import './style.css'
import { useForm } from 'react-hook-form'
import { URL } from '../../config/url'
import { useNavigate } from 'react-router-dom'
import Usuarios from '../../services/Usuarios'
import ButtonFilterAndSelect from '../../components/ButtonFilterAndSelect'
import ButtonLimparAndFiltrar from '../../components/ButtonLimparAndFiltrar'
import { useAuth } from '../../context/auth.js'

import TableList from '../../components/TableComponents/TableList'
import TableData from './TableData'

const ListaUsuarios = () => {

  const { user } = useAuth()
  const { register, getValues, handleSubmit, reset } = useForm()
  const navigate = useNavigate()

  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRegistros, setTotalRegistros] = useState(0);

  const [show, setShow] = useState(false);
  const [filter, setFilter] = useState(false);

  useEffect(() => {
    listAllUsers(1);
  }, []);


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function listAllUsers(pageNumber) {
    setLoading(true);

    const values = getValues()

    let newFiltro = {
      ativo: values.ativo ?? true,
      nome: values.nome ?? '',
      email: values.email ?? '',
    }

    Usuarios.listAll(pageNumber, newFiltro)
      .then((data) => {
        setUsers(data.data.data);
        setTotalPages(data.data.paging.total_pages)
        setTotalRegistros(data.data.paging.total_records)
      })
      .catch((error) => {
        SweetAlert.error(error.response.data.message, 'Usuários');
      })
      .finally(() => {
        // setFiltros(newFiltro)
        setLoading(false)
      });
  }

  const handlePageChange = (selected) => {
    setPage(selected.selected + 1)
    listAllUsers(selected.selected + 1);
  };

  function onFilter() {
    setFilter(true)
    setShow(false)
    listAllUsers(1)
  }

  function deleteUser(id) {
    setLoading(true)
    Usuarios.delete(id)
      .then((data) => {
        // SweetAlert.success('Categoria deletada com sucesso!', 'Categorias');
        listAllUsers()
        // setLoading(false)
      })
      .catch((error) => {
        SweetAlert.error(error.response.data.message, 'Usuários');
      })
      .finally(() => setLoading(false))
  }

  function deleteUserAlert(id) {
    SweetAlert.question('Atenção', 'Deletar usuário?', 'SIM!', () => deleteUser(id), '#FF0000')
  }

  function editUser(id) {
    navigate(URL.usuarios_cadastro + '/' + id)
  }


  return (
    <>
      {loading === true ? <Loading /> : ''}

      <Offcanvas show={show} onHide={handleClose} style={{ height: '100vh', backgroundColor: '#f1f1f1' }} placement='bottom'>
        <Form onSubmit={handleSubmit(onFilter)}>
          <Container>
            <Offcanvas.Header closeButton>
              <TopTitlePage title='Filtro' />
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Card className='cardShadow'>
                <Card.Body className='p-3'>
                  <Row className='d-flex align-items-end'>
                    <Form.Group className="col-12 col-md-2" >
                      <Form.Label>Situação</Form.Label>
                      <Form.Select {...register('ativo')} size='sm'>
                        <option value={true}>ATIVO</option>
                        <option value={false}>INATIVO</option>
                        <option value=''>TODOS</option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group className="col-12 col-md-3">
                      <Form.Label>Nome</Form.Label>
                      <Form.Control size='sm' type="text" {...register('nome')} />
                    </Form.Group>


                    <Form.Group className="col-12 col-md-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control size='sm' type="email" {...register('email')} />
                    </Form.Group>
                  </Row>

                </Card.Body>
              </Card>
              <div className='d-flex justify-content-end mt-3 gap-3'>
                <ButtonLimparAndFiltrar
                  handleClose={handleClose}
                  reset={reset}
                  listAll={listAllUsers}
                  setFilter={setFilter}
                />
              </div>
            </Offcanvas.Body>
          </Container>
        </Form>
      </Offcanvas>

      <div>
        <TopTitlePage title='Usuários' />

        <div className='py-3 d-flex justify-content-between px-3 align-items-end gap-2 flex-column flex-md-row'>

          <ButtonFilterAndSelect
            handleShow={handleShow}
            listAll={listAllUsers}
            filter={filter}
          />

          <div className='d-flex align-items-end gap-3 col-12 col-md-auto'>
            <Button
              variant='success'
              onClick={() => navigate(URL.usuarios_cadastro)}
              className='col-12 col-md-auto'
            >+ Novo usuário</Button>
          </div>
        </div>

        <Card className='mx-3 cardShadow mb-2 lg-mb-0'>
          <Card.Body className='p-0'>


            <TableList>
              <TableData
                array={users}
                editFunction={editUser}
                deleteFunction={deleteUserAlert}
                user={user}
              />
            </TableList>


            <div className='mt-4 d-flex justify-content-between px-3'>
              <span> Total: {totalRegistros}</span>
              <ReactPaginate
                pageCount={totalPages}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                onPageChange={handlePageChange}
                containerClassName={'pagination_container'}
                activeClassName={'active'}
                previousLabel={'<'}
                nextLabel={'>'}
              />
            </div>


          </Card.Body>
        </Card>

      </div>
    </>
  )
}

export default ListaUsuarios