const security = {}

security.validation = api => {
    return new Promise((resolve, reject) => {
        api
            .then(dados => {
                // const { data } = dados

                // if (!data) return reject({ code: 1, message: 'Nenhuma informação recebida!' })

                return resolve(dados)
            })
            .catch(error => {
                const data = error?.response?.data

                if (!error.response) return reject({ code: 0, message: 'Oi, tudo bem? <br/> Sem resposta com o servidor.. Aguarde alguns segundos e tente novamente!', data })
                // if (error.response.status === 400) return reject({ code: 400, message: 'Oi, tudo bem? <br/> Requisição inválida!', data })
                // if (error.response.status === 401) return reject({ code: 401, message: 'Oi, tudo bem? <br/> Faça login para continuar!', data })
                // if (error.response.status === 404) return reject({ code: 404, message: 'Oi, tudo bem? <br/> Registro não encontrado!', data })
                // if (error.response.status === 403) return window.location.reload()
                // if (error.response.status === 429) return reject({ code: 429, message: 'Oi, tudo bem? <br/> Você fez muitas tentativas.. Aguarde alguns segundos e tente novamente!', data })

                return reject(error)
            })
    })
}

export default security