import React from 'react'
import { Button } from 'react-bootstrap'
import { FiFilter } from 'react-icons/fi'
import { IoIosRefresh } from 'react-icons/io'
import './ButtonFilterAndSelect.css'

const ButtonFilterAndSelect = ({ handleShow, listAll, filter = false, removeAllSelected = () => { } }) => {
  return (
    <div style={{ position: 'relative' }} className='d-flex gap-2 col-12 col-md-auto flex-column flex-md-row'>
      <Button
        variant='dark'
        onClick={handleShow}
        className='d-flex align-items-center gap-1 col-12 col-md-auto justify-content-center'
      >
        <FiFilter /> Filtros
        {filter && <div className="notification-dot"></div>}
      </Button>
      <Button
        variant='primary'
        onClick={() => { listAll(1); removeAllSelected() }}
        className='d-flex align-items-center gap-1 col-12 col-md-auto justify-content-center'
      >
        <IoIosRefresh /> Atualizar
      </Button>
    </div>
  )
}

export default ButtonFilterAndSelect
