import React from 'react'
import { FaSave } from 'react-icons/fa'

const ButtonSaveContent = () => {
  return (
    <>
      <FaSave /> Salvar
    </>
  )
}

export default ButtonSaveContent