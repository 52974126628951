import React from 'react'
import './style.css'

const TableBodyRowStart = ({ text }) => {
  return (
    <td className='tableRowStart' >
      {text}
    </td>
  )
}

export default TableBodyRowStart