import React from 'react'

const TopTitlePage = ({ title }) => {

  return (
    <div className='d-flex justify-content-between align-items-center px-3 py-3 textPrimary'>
      <h2>{title}</h2>
    </div>
  )
}

export default TopTitlePage