import { useAuth } from "../context/auth";
import Loading from "./loading";
import Main from "./main";

function Routes() {
  const { isLoading } = useAuth();
  return isLoading ? <Loading /> : <Main />;
}

export default Routes;
