import React, { useEffect, useState } from 'react'
import { Button, Card, Form, Spinner } from 'react-bootstrap'
import { useAuth } from '../../context/auth';
import { useForm } from 'react-hook-form';
import { SweetAlert } from '../../components/sweetalert';
import TopTitlePage from '../../components/TopTitlePage';
import SessionsServices from '../../services/sessions';

import './style.css'
import ButtonSaveContent from '../../components/ButtonSaveContent';

const Perfil = () => {

  const { user, setUser } = useAuth();
  const { register, setValue, getValues } = useForm();

  const [loadingRecuperar, setLoadingRecuperar] = useState(false)

  useEffect(() => {
    if (user) {
      setValue('email', user.email);
      setValue('name', user.name);
    }
  }, [user]);

  async function atualizaPerfil() {
    const values = getValues();

    if (values.email !== user.email) {

    }

    const perfil = {
      email: values.email,
      name: values.name
    }

    try {
      const { data } = await SessionsServices.atualizarPerfil(perfil)
      setUser(data)
      SweetAlert.success('Atualizado com sucesso!', 'Perfil');
    } catch (error) {
      alert('Perfil', error);
    }
  }

  async function handlerRecuperarSenha() {
    try {
      const email = getValues('email')

      if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        return SweetAlert.error('Informe um email válido', 'Login');
      }

      const body = { ...(email.includes('@') ? { email } : { username: email }) }

      setLoadingRecuperar(true)
      await SessionsServices.login_recuperar_senha(body)
      SweetAlert.success('Verifique o seu e-mail!', 'Recuperar Senha')
      setLoadingRecuperar(false)
    } catch (error) {
      setLoadingRecuperar(false)
      SweetAlert.error(error.response.data.message, 'Recuperar Senha');
    }
  }

  return (
    <div>

      <TopTitlePage title='Perfil' />

      <div className='d-flex justify-content-center px-3'>
        <Card className='col-11 col-md-12 cardShadow d-flex '>
          <Form className='pb-3 pt-3 ps-2 ps-md-4 pe-2 pe-md-4 '>
            <Form.Group className="mb-3 col-md-4 col-12">
              <Form.Label>Nome</Form.Label>
              <Form.Control type="text" {...register('name')} />
            </Form.Group>
            <Form.Group className="mb-3 col-md-4 col-12">
              <Form.Label>Email</Form.Label>
              <Form.Control type="text" {...register('email')} />
            </Form.Group>
            <div className='d-flex justify-content-between justify-content-md-start gap-4'>
              <Button variant="outline-danger" onClick={handlerRecuperarSenha} disabled={loadingRecuperar}>
                {loadingRecuperar === true ?
                  <span>
                    <Spinner animation="border" size="sm" /> Aguarde...{' '}
                  </span>
                  :
                  <span>
                    Alterar senha
                  </span>}
              </Button>
              <Button variant="success" onClick={atualizaPerfil} className='d-flex align-items-center gap-1'> <ButtonSaveContent /></Button>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  )
}

export default Perfil