import React from 'react'
import TableHeadRowCenter from '../../components/TableComponents/TableHeadRowCenter'
import TableHeadRowStart from '../../components/TableComponents/TableHeadRowStart'
import TableBodyRowCenter from '../../components/TableComponents/TableBodyRowCenter'
import TableBodyRowStart from '../../components/TableComponents/TableBodyRowStart'
import { Button } from 'react-bootstrap'
import { FaCheck } from 'react-icons/fa'

const TableData = ({ array, editFunction, deleteFunction, user }) => {
  return (
    <>
      <thead>
        <tr>
          <TableHeadRowCenter text='Ativo' />
          <TableHeadRowStart text='Nome' />
          <TableHeadRowCenter text='Ações' />
        </tr>
      </thead>

      <tbody>
        {array?.map((cliente, index) => {

          if (cliente.name === "DESENVOLVEDOR" && user.id !== "b05468fb-a5d8-4b2a-a3d0-58029098dbfa") {
            return null
          }
          return (
            <tr key={cliente.codigo} className='trBody'>
              {/* <TableBodyRowCenter text={cliente.ativo === true ? <FaCheck /> : <FaX />} /> */}
              <TableBodyRowCenter text={<FaCheck />} />
              <TableBodyRowStart text={cliente.name} />

              <td className='tableRowCenter'>
                <div className='buttonContainer'>
                  <Button variant='outline-secondary' className='m-0 p-0 px-2' onClick={() => editFunction(cliente.id)} >Editar</Button>
                  <Button variant='outline-danger' className='m-0 p-0 px-2' onClick={() => { deleteFunction(cliente.id) }} >Remover</Button>
                </div>
              </td>
            </tr>
          )
        })}
      </tbody>
    </>
  )
}

export default TableData