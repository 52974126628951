import React from 'react';
import { Container } from 'react-bootstrap';
import CriarUsuarioInterno from '../criar_usuario_interno';

const UsuariosCadastro = () => {

  return (
    <>
      <Container>
        <CriarUsuarioInterno />
      </Container>
    </>
  );
}

export default UsuariosCadastro;
