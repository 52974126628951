import React, { useEffect, useState } from 'react'
import { Button, Card, Form, Row } from 'react-bootstrap'
import Loading from '../../components/Loading'
import { SweetAlert } from '../../components/sweetalert'

// import ReactPaginate from 'react-paginate';
import './style.css'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import CNPJ from '../../services/CNPJ'

// import InputDataList from '../../components/InputDataList'
import { formatCPFOrCNPJ } from '../../utilities/formatCPForCNPJ'
import { formatarDocumento } from '../../utilities/formatCpfCnpj'
import { startOfMonth, endOfMonth, format, differenceInDays, parseISO } from 'date-fns';
import { FiFilter } from 'react-icons/fi'
import CardEmpresa from './CardEmpresa'
import { GoTrash } from "react-icons/go";

import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";


const ListaCNPJ = () => {

  const ufs = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO']

  const { register, getValues, reset, setValue, watch } = useForm()
  const location = useLocation()

  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)

  let page = watch('page')

  // const [CNAEList, setCNAEList] = useState([]);
  // const [localizacaoList, setLocalizacaoList] = useState([]);
  // const [naturezaList, setNaturezaList] = useState([]);

  const currentDate = new Date(); // Obtém a data atual
  const firstDayOfMonth = startOfMonth(currentDate); // Obtém o primeiro dia do mês vigente
  const lastDayOfMonth = endOfMonth(currentDate); // Obtém o último dia do mês vigente

  // Formatando as datas para exibição
  const formattedFirstDay = format(firstDayOfMonth, 'yyyy-MM-dd');
  const formattedLastDay = format(lastDayOfMonth, 'yyyy-MM-dd');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const filtroURL = {};

    // Itera sobre todos os parâmetros na URL
    for (const [key, value] of params.entries()) {
      // Adiciona os parâmetros na estrutura de filtro
      filtroURL[key] = decodeURIComponent(value);
    }

    // Verifica se os valores estão definidos e separa corretamente
    // const cnaeArray = filtroURL.CNAE ? filtroURL.CNAE.split(';') : [];
    // const localizacaoArray = filtroURL.localizacao ? filtroURL.localizacao.split(';') : [];
    // const naturezaArray = filtroURL.natureza ? filtroURL.natureza.split(';') : [];

    // Atualiza os estados com os arrays
    // setCNAEList(cnaeArray);
    // setLocalizacaoList(localizacaoArray);
    // setNaturezaList(naturezaArray);

    // Define os valores dos inputs do formulário
    setValue('data_inicial', filtroURL.data_inicial || '');
    setValue('data_final', filtroURL.data_final || '');
    setValue('situacao', filtroURL.situacao || '');
    setValue('portes', filtroURL.portes || '');
    setValue('capital_social', filtroURL.capital_social || '');
    setValue('mei', filtroURL.mei || '');
    setValue('simples', filtroURL.simples || '');
    setValue('uf', filtroURL.uf || '');
    setValue('cidade', filtroURL.cidade || '');
    setValue('cnpj', formatarDocumento(filtroURL.cnpj) || '');
    // setValue('razao_social', formatarDocumento(filtroURL.razao_social) || '');

    if (filtroURL.page === undefined) {
      setValue('page', 1)
    } else {
      setValue('page', Number(filtroURL.page))
      // setPage(Number(filtroURL.page))
    }

    let newPage = getValues('page')

    const filtro = {
      page: Number(newPage),
      // CNAE: cnaeArray.join(';') || '',
      // localizacao: localizacaoArray.join(';') || '',
      data_inicial: filtroURL.data_inicial || '',
      data_final: filtroURL.data_final || '',
      // natureza: naturezaArray.join(';') || '',
      situacao: filtroURL.situacao || '',
      portes: filtroURL.portes || '',
      capital_social: filtroURL.capital_social || '',
      mei: filtroURL.mei || '',
      simples: filtroURL.simples || '',
      uf: filtroURL.uf || '',
      cidade: filtroURL.cidade || '',
      cnpj: filtroURL.cnpj || '',
      // razao_social: filtroURL.razao_social || '',
    };

    if (todosCamposVazios(filtro)) {
      filtro.data_inicial = formattedFirstDay
      filtro.data_final = formattedLastDay
      filtro.page = 1
      setValue('data_inicial', formattedFirstDay);
      setValue('data_final', formattedLastDay);
    }

    // Chama a função de listagem de usuários com os filtros
    listAllUsers(filtro);
  }, []);

  function todosCamposVazios(obj) {
    for (let chave in obj) {
      if (obj.hasOwnProperty(chave) && obj[chave] !== "") {
        return false; // Se encontrar algum campo preenchido, retorna falso
      }
    }
    return true; // Se todos os campos estiverem vazios, retorna verdadeiro
  }

  function listAllUsers(newFiltro) {
    setLoading(true);


    const inicio = parseISO(newFiltro.data_inicial);
    const fim = parseISO(newFiltro.data_final);

    const diferencaDias = differenceInDays(fim, inicio);

    if (diferencaDias > 366
      //  || isNaN(diferencaDias)
    ) {
      setLoading(false);
      return SweetAlert.error('O intervalo máximo permitido é de 1 ano!', 'Data inválida');
    }

    if (newFiltro.page === undefined) {
      // page = 1
      setValue('page', 1)
    }

    updateUrl(newFiltro);

    CNPJ.listAll(newFiltro.page, newFiltro)
      .then((data) => {
        setItems(data.data.data);
        // setTotalPages(data.data.paging.total_pages);
        // setTotalRegistros(data.data.paging.total_records);
      })
      .catch((error) => {
        SweetAlert.error(error.response.data.message, 'CNPJ');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function updateUrl(params) {
    const queryString = buildQueryString(params);
    const newUrl = `${location.pathname}${queryString ? `?${queryString}` : ''}`;

    window.history.replaceState({}, '', newUrl);
  }

  function buildQueryString(params) {
    const searchParams = new URLSearchParams();

    for (let key in params) {
      if (params[key] !== null && params[key] !== undefined && params[key] !== '') {
        searchParams.set(key, encodeURIComponent(params[key])); // Codifica o valor antes de definir
      } else {
        searchParams.delete(key); // Remove o parâmetro se o valor for nulo, undefined ou vazio
      }
    }

    return searchParams.toString();
  }

  const handlePageChange = (tipo) => {

    const values = getValues();

    // Construa o filtro com os valores do formulário
    const filtro = {
      page: values.page || '',
      // CNAE: CNAEList.join(';') || '',
      // localizacao: localizacaoList.join(';') || '',
      data_inicial: values.data_inicial || '',
      data_final: values.data_final || '',
      // natureza: naturezaList.join(';') || '',
      situacao: values.situacao || '',
      portes: values.portes || '',
      capital_social: values.capital_social || '',
      mei: values.mei || '',
      simples: values.simples || '',
      uf: values.uf || '',
      cidade: values.cidade || '',
      cnpj: values.cnpj || '',
      // razao_social: values.razao_social || '',
    };

    if (tipo === 'next') {
      filtro.page = filtro.page + 1
      // page = page + 1
      setValue('page', filtro.page);
      // setPage(prev => prev + 1)
      listAllUsers(filtro);
    }

    if (tipo === 'previous') {
      if (filtro.page === 1) {
        setValue('page', 1);
      } else {
        filtro.page = filtro.page - 1
        setValue('page', filtro.page);
      }
      // setValue('page', Number(filtro.page) - 1);
      // setPage(prev => prev - 1)
      listAllUsers(filtro);
    }

    window.scrollTo(0, 0);
  };

  function onFilter() {
    const values = getValues();

    setValue('page', 1)

    // Construa o filtro com os valores do formulário
    const filtro = {
      page: 1,
      // CNAE: CNAEList.join(';') || '',
      // localizacao: localizacaoList.join(';') || '',
      data_inicial: values.data_inicial || '',
      data_final: values.data_final || '',
      // natureza: naturezaList.join(';') || '',
      situacao: values.situacao || '',
      portes: values.portes || '',
      capital_social: values.capital_social || '',
      mei: values.mei || '',
      simples: values.simples || '',
      uf: values.uf || '',
      cidade: values.cidade || '',
      cnpj: values.cnpj || '',
      // razao_social: values.razao_social || '',
    };

    // if (filtro.razao_social.length < 3 || filtro.razao_social === null || filtro.razao_social !== '') {
    // if (filtro.razao_social && filtro.razao_social.length < 3) {
    //   setLoading(false);
    //   return SweetAlert.error('O mínimo de caracteres é 3!', 'Razão social inválida');
    // }

    // Chame a função listAllUsers passando o filtro construído
    listAllUsers(filtro);
  }

  function clearFilter() {
    reset()
    // setCNAEList([])
    // setNaturezaList([])
    // setLocalizacaoList([])
    setValue('data_inicial', '')
    setValue('data_final', '')
    setValue('page', 1)
    window.history.replaceState({}, '', `${window.location.pathname}`);
  }

  // const removeOption = (index, list, setList) => {
  //   const newList = [...list];
  //   newList.splice(index, 1);
  //   setList(newList);
  // };

  return (
    <>
      {loading === true ? <Loading /> : ''}

      <div>

        <Form>
          <Card className='cardShadow mx-3'>
            <Card.Body className='p-3'>
              <Row className='d-flex align-items-end'>
                {/* <Form.Group className="col-12 col-md-4" >
                  <Form.Label>Ramo de atividade [CNAE]</Form.Label>

                  <InputDataList
                    options={['32423', '238732094', '289742394', '38492']}
                    register={register}
                    registerName='CNAE'
                    list={CNAEList}
                    setList={setCNAEList}
                  />

                </Form.Group> */}

                {/* <Form.Group className="col-12 col-md-4">
                  <Form.Label>Localização</Form.Label>
                  <InputDataList
                    options={['SC-Criciúma', 'SC-Içara', 'RS-Porto Alegre', 'SP-São Paulo']}
                    register={register}
                    registerName='localizacao'
                    list={localizacaoList}
                    setList={setLocalizacaoList}
                  />
                </Form.Group> */}

                {/*<Form.Group className="col-12 col-md-2">
                  <Form.Label>Razão Social</Form.Label>
                  <Form.Control
                    size='sm'
                    type="text"
                    {...register('razao_social')}
                  />
                </Form.Group> */}

                <Form.Group className="col-12 col-md-2">
                  <Form.Label>CNPJ</Form.Label>
                  <Form.Control
                    size='sm'
                    type="text"
                    {...register('cnpj')}
                    onChange={(e) => formatCPFOrCNPJ(e, setValue, 'cnpj')}
                  />
                </Form.Group>

                <Form.Group className="col-12 col-md-2">
                  <Form.Label>Cidade</Form.Label>
                  <Form.Control size='sm' type="text" {...register('cidade')}
                  />
                </Form.Group>

                <Form.Group className="col-12 col-md-2 mt-2" >
                  <Form.Label>UF</Form.Label>
                  <Form.Select {...register('uf')} size='sm'>
                    <option value=''>TODOS</option>
                    {
                      ufs.map(uf => {
                        return (
                          <option value={uf}>{uf}</option>
                        )
                      })
                    }
                  </Form.Select>
                </Form.Group>



              </Row>

              <Row className='d-flex align-items-end'>

                {/* <Form.Group className="col-12 col-md-4" >
                  <Form.Label>Natureza jurídica</Form.Label>

                  <InputDataList
                    options={['natureza 1', 'natureza 2', 'natureza 3', 'natureza 4']}
                    register={register}
                    registerName='natureza'
                    list={naturezaList}
                    setList={setNaturezaList}
                  />

                </Form.Group> */}

                <Form.Group className="col-12 col-md-2">
                  <Form.Label>Data inicial</Form.Label>
                  <Form.Control size='sm' type="date" {...register('data_inicial')}
                  // defaultValue={formattedFirstDay}
                  />
                </Form.Group>
                <Form.Group className="col-12 col-md-2">
                  <Form.Label>Data final</Form.Label>
                  <Form.Control size='sm' type="date" {...register('data_final')}
                  // defaultValue={formattedLastDay}
                  />
                </Form.Group>

                <Form.Group className="col-12 col-md-2 mt-2" >
                  <Form.Label>Situação</Form.Label>
                  <Form.Select size='sm'{...register('situacao')}>
                    <option value=''>TODOS</option>
                    <option value='ATIVA'>ATIVA</option>
                    <option value='SUSPENSA'>SUSPENSA</option>
                    <option value='INAPTA'>INAPTA</option>
                    <option value='BAIXADA'>BAIXADA</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="col-12 col-md-2 mt-2" >
                  <Form.Label>Portes</Form.Label>
                  <Form.Select size='sm'{...register('portes')}>
                    <option value=''>TODOS</option>
                    <option value='MICRO_EMPRESA'>MICROEMPRESA</option>
                    <option value='EMPRESA_PEQUENO_PORTE'>EMPRESA DE PEQUENO PORTE</option>
                    <option value='DEMAIS'>DEMAIS</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="col-12 col-md-2 mt-2" >
                  <Form.Label>Capital social</Form.Label>
                  <Form.Select size='sm'{...register('capital_social')}>
                    <option value=''>TODOS</option>
                    <option value='0-50'>R$0 - R$50 mil</option>
                    <option value='50-100'>R$50 mil - R$100 mil</option>
                    <option value='100-1000'>R$100 mil - R$1 milhão</option>
                    <option value='1000-9999'>Acima de R$1 milhão</option>
                  </Form.Select>
                </Form.Group>


              </Row>

              <Row className='d-flex align-items-end justify-content-end'>
                {/* <div className='d-flex col-12 col-md-auto gap-2 flex-column flex-md-row'>
                  <Form.Group className="col-12 mt-2" >
                    <Form.Label>Opção pelo MEI</Form.Label>
                    <Form.Select {...register('mei')} className='col-12'>
                      <option value=''>TODOS</option>
                      <option value='sim'>SIM</option>
                      <option value='nao'>NÃO</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="col-12 mt-2" >
                    <Form.Label>Opção pelo SIMPLES</Form.Label>
                    <Form.Select {...register('simples')}>
                      <option value=''>TODOS</option>
                      <option value='sim'>SIM</option>
                      <option value='nao'>NÃO</option>
                    </Form.Select>
                  </Form.Group>
                </div> */}

                <div className='d-flex col-12 col-md-auto gap-2 flex-column flex-md-row'>
                  <Button variant='danger' className='mt-3 mt-md-2' onClick={() => clearFilter()}><GoTrash /> Limpar filtros</Button>
                  <Button variant='dark' className='mt-3 mt-md-2' onClick={() => onFilter()}><FiFilter /> Filtrar</Button>
                </div>
              </Row>
            </Card.Body>
          </Card>
        </Form>

        {/* {CNAEList.length > 0 ?
          <Card className='cardShadow mx-3 mt-2 p-3'>
            <Card.Title>CNAE's selecionados <span className='X-remove-all' onClick={() => setCNAEList([])}>X</span></Card.Title>
            <Card.Body className='d-flex gap-2 p-0'>
              {CNAEList.map((option, index) => (
                <div key={index} className="selected-card">
                  {option}
                  <span className="remove-btn" onClick={() => removeOption(index, CNAEList, setCNAEList)}>x</span>
                </div>
              ))}

            </Card.Body>
          </Card> : ''}

        {localizacaoList.length > 0 ?
          <Card className='cardShadow mx-3 mt-2 p-3'>
            <Card.Title>Localizações selecionadas <span className='X-remove-all' onClick={() => setLocalizacaoList([])}>X</span></Card.Title>
            <Card.Body className='d-flex gap-2 p-0'>
              {localizacaoList.map((option, index) => (
                <div key={index} className="selected-card">
                  {option}
                  <span className="remove-btn" onClick={() => removeOption(index, localizacaoList, setLocalizacaoList)}>x</span>
                </div>
              ))}

            </Card.Body>
          </Card> : ''}

        {naturezaList.length > 0 ?
          <Card className='cardShadow mx-3 mt-2 p-3'>
            <Card.Title>Naturezas de operação selecionadas <span className='X-remove-all' onClick={() => setNaturezaList([])}>X</span></Card.Title>
            <Card.Body className='d-flex gap-2 p-0'>
              {naturezaList.map((option, index) => (
                <div key={index} className="selected-card">
                  {option}
                  <span className="remove-btn" onClick={() => removeOption(index, naturezaList, setNaturezaList)}>x</span>
                </div>
              ))}

            </Card.Body>
          </Card> : ''} */}

        <Card className='mx-3 cardShadow mb-2 lg-mb-0 mt-3'>
          <Card.Body className='p-0'>

            {items.length === 0 ? (
              <div className='d-flex justify-content-center m-3'>
                <p>Nenhum registro encontrado</p>
              </div>
            ) : (
              items.map((item, index) => (
                <CardEmpresa item={item} key={index} />
              ))
            )}


            <div className='mt-4 mb-2 d-flex justify-content-end px-3 gap-3'>
              {/* <span> Total: {totalRegistros}</span>
              <ReactPaginate
                pageCount={totalPages}
                pageRangeDisplayed={1}
                marginPagesDisplayed={1}
                onPageChange={handlePageChange}
                containerClassName={'pagination_container'}
                activeClassName={'active'}
                previousLabel={'<'}
                nextLabel={'>'}
              /> */}
              <Button variant='outline-secondary' onClick={() => handlePageChange('previous')}><FaArrowLeft /></Button>
              <Button variant='outline-secondary'>{page}</Button>
              <Button variant='outline-secondary' onClick={() => handlePageChange('next')}><FaArrowRight /></Button>
            </div>

          </Card.Body>
        </Card>

      </div>
    </>
  )
}

export default ListaCNPJ