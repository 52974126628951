export function formatarDocumento(documento) {
  if (documento === undefined) return
  // Remove caracteres não numéricos
  const numeroLimpo = documento.replace(/\D/g, '');

  // Verifica se é um CPF ou CNPJ
  if (numeroLimpo.length === 11) {
    // Formata CPF: 123.456.789-01
    return numeroLimpo.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  } else if (numeroLimpo.length === 14) {
    // Formata CNPJ: 12.345.678/0001-90
    return numeroLimpo.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  } else {
    // Retorna a string original se não for CPF nem CNPJ
    return documento;
  }
}